@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-core-4);
    position: fixed;
    right: var(--spacing-core-4);
    height: var(--sizing-core-12);
    padding: 0 var(--spacing-core-4);
    bottom: calc(var(--sizing-core-10) * -1);
    z-index: 100;
    background-color: var(--color-background-inverted-default);
    opacity: 0;
    transition:
        opacity var(--motion-duration-core-medium),
        transform var(--motion-duration-core-medium);
    appearance: none;
    border: none;
    margin: 0;
    border-radius: var(--border-radius-core-s);
    cursor: pointer;

    @include mq.mq($from: medium) {
        left: 50%;
        margin-left: -50px;
        right: unset;
    }
}

.visible {
    opacity: 0.65;
    transform: translateY(calc(var(--sizing-core-12) * -1));

    &:hover {
        opacity: 1;
    }
}

.icon {
    color: var(--color-foreground-inverted-default);
    transform: translateY(2px);
}

.text {
    color: var(--color-foreground-inverted-default);
    display: none;

    @include mq.mq($from: medium) {
        display: inline;
    }
}


