.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.root {
    width: 100%;
    max-width: 954px;
    padding: var(--spacing-core-2);
    flex-direction: column;
}