.icon {
    flex-shrink: 0;
}

.servings {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-core-2);

    p {
        margin: 0;
    }
}
