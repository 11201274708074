@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    display: none;
}

.content {
    position: relative;
    padding: var(--spacing-core-10);
}

@include mq.mq($from: large) {
    .root {
        display: block;
        width: 365px;
        flex-basis: 365px;
        flex-shrink: 0;
    }    
}
