@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    flex-basis: 100%;
    padding: 0 0 var(--spacing-core-3);
    list-style: none;
    border-bottom: 1px solid var(--color-background-tertiary-default);
}

.noBorder {
    border-bottom: none;
}

.crumbs {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq.mq($from: medium) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.home {
    display: inline-block;
    padding: 0;
    list-style: none;
    vertical-align: sub;

    svg {
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
        svg {
            fill: var(--color-accent-primary);
        }
    }
}

.crumb {
    &:not(:last-of-type) {
        margin-right: 12px;
    }

    &:not(.home) {
        position: relative;
        display: inline;
        padding: 0 0 0 15px;
        color: var(--color-foreground-primary-default);
        text-decoration: none;

        &::before {
            $size: 4px;

            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: $size;
            height: $size;
            margin: auto;
            border-radius: 50%;
            background: var(--color-foreground-primary-default);
        }
    }
}

.link {
    position: relative;
    display: inline-block;
    color: var(--color-foreground-primary-default);
    text-decoration: none;

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 1px;
        margin: auto;
        background: var(--color-foreground-primary-default);
        transition: all 0.2s ease-in-out;
        content: '';
    }

    &:hover,
    &:active,
    &:focus {
        &::after {
            opacity: 1;
        }
    }
}

.linkLabel {
    text-transform: capitalize;
}

.back {
    margin-left: -6px;
    color: var(--color-foreground-primary-default);
    background-color: transparent;
    border: none;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }

    @include mq.mq($from: medium) {
        display: none;
    }
}

.backLabel,
.backIcon {
    vertical-align: middle;
}
