@use '../../../../../styles/vars' as *;

.root {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: $lane-width;
    display: flex;
    flex-direction: row;
    background-color: var(--color-background-default);
    min-height: calc(100vh - 168px);
    padding: 0;
}

.graySidebar {
    position: fixed;
    background-color: var(--color-background-secondary-default);
    left: 50%;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: -1;
}

