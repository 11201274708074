@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.content {
    margin: var(--sizing-core-3) 0;
    display: flex;
    flex-direction: column;
    gap: var(--sizing-core-2);

    @include mq.mq($from: medium) {
        margin: var(--sizing-core-6) 0;
    }

    @include mq.mq($from: large) {
        margin: var(--sizing-core-8) 0;
        gap: var(--sizing-core-4);
    }
}
