@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.breadcrumbsWrapper {
    display: flex;
    align-content: space-between;
    flex-direction: row;
}

.favorite {
    border-bottom: 1px solid var(--color-background-tertiary-default);
    padding-bottom: var(--spacing-core-3);
    display: block;

    @include mq.mq($from: large) {
        display: none;
    }
}
