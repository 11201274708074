.root {
    display: inline-flex;
    gap: var(--spacing-core-2);
    background-color: var(--color-background-primary-disabled);
    height: var(--sizing-core-12);    
    cursor: pointer;
    overflow: hidden;
    color: var(--color-foreground-primary-default);
    border: none;
    border-radius: var(--border-radius-core-circle);
    align-items: center;
    padding: 0 var(--spacing-core-6)
}

.filled {
    background: var(--color-background-inverted-default);
    color: var(--color-foreground-inverted-default);
    overflow: hidden;
    cursor: pointer;

    &:hover {
        background: var(--color-background-inverted-hover);
        color: var(--color-foreground-inverted-hover);
    }
}