@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.dash {
    width: 2px;
    margin: var(--spacing-core-2) auto;
    height: var(--sizing-core-6);
    background: var(--color-action-primary-default);

    @include mq.mq($from: medium) {
        margin: var(--sizing-core-4) auto;
    }
}
