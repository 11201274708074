@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--color-background-secondary-default);

    > * {
        padding: var(--spacing-core-3);
    }
}

@include mq.mq($from: large) {
    .root {
        padding: var(--spacing-core-8);
    }
}
