@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    margin-top: var(--spacing-core-6);
    margin-bottom: var(--spacing-core-3);
}

.dishesWrapper {
    margin-bottom: var(--spacing-core-8);
    flex-wrap: wrap;
}

.nutrition {
    &Wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing-core-2);

        @include mq.mq($from: 375px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mq.mq($from: small) {
            grid-template-columns: repeat(2, minmax(0, 343px));
        }
    }

    &Subtext {
        color: var(--color-foreground-secondary-default);
        margin: var(--spacing-core-2) 0;

        a {
            color: var(--color-foreground-secondary-default);
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 2px;
        }
    }
}

.ingredientsWrapper {
    margin-top: var(--spacing-core-3);
    max-width: 100%;
}

.buttonsWrapper {
    display: flex;
    flex-direction: column-reverse;
    min-width: 280px;
    width: 100%;
    @include mq.mq($from: medium) {
        flex-direction: row-reverse;
        gap: var(--spacing-core-2);
        justify-content: flex-end;
    }
}

.saveButton {
    margin-bottom: var(--spacing-core-2);
    width: auto;

    @include mq.mq($from: medium) {
        width: fit-content;
        min-width: 168px;
    }
}

.disclaimerText {
    padding: var(--spacing-core-6) 0;    
    color: var(--color-foreground-secondary-default);

    a {
        color: var(--color-foreground-secondary-default);
        text-decoration: underline;
    }
}

.removePreferencesButton {
    color: var(--color-action-destructive-default);
    @include mq.mq($until: medium) {
        text-align: center;
        width: 100%;
    }
    @include mq.mq($from: medium) {
        width: auto;
        padding: {
            left: 0;
            right: 0;
        }
    }
}

.chipImage {
    width: var(--sizing-core-8);
    height: var(--sizing-core-8);
}

.submitError {
    color: var(--color-action-destructive-default);
    margin-bottom: var(--spacing-core-4);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-core-2);
}
