@use '~@royalaholddelhaize/design-system-pantry-web/utils/mq' as mq;

.root {
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-core-4);
}

@include mq.mq($from: large) {
    .root {
        gap: var(--spacing-core-6);
        flex-wrap: nowrap;
    }
}
