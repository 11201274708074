@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq.mq($from: large) {
        padding-top: var(--spacing-core-8);
    }
}

.icon {
    fill: var(--color-background-secondary-hover);
}

.title {
    margin-bottom: var(--spacing-core-2);
}

.searchText {
    color: var(--color-action-primary-default);
}
