@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: block;
    height: 100%;
    cursor: pointer;
    position: sticky;
    z-index: 10;
    border: none;
    background: transparent;

    @include mq.mq($from: large) {
        box-shadow: 0 2px 2px rgb(0 0 0 / 8%),0 2px 8px rgb(0 0 0 / 4%);
        background: var(--color-background-primary-default);
        border-radius: 40px;
        transition: all ease-in-out .2s;

        &:hover {
            box-shadow: 0 16px 32px rgb(0 0 0 / 10%);
        }

        &:active {
            transform: translateY(1px);
        }
    }
}

.button {
    height: 100%;
    outline: none;
    display: flex;
    align-items: center;

    @include mq.mq($from: large) {
        padding: var(--spacing-core-3) var(--spacing-core-4);
    }
}

.text {
    margin-left: var(--spacing-core-2);
    user-select: none;
}

@include mq.mq($from: large) {
    .favoritePopover {
        top: 0;
    }
}
